'use strict';

// #SFRA_SYNCED
// Selective SFRA updated. Script has differences #CDIFF BS <> GG <> PF <> SN <> FM
// Current version is BS-based with delta sync from other site-specific versions.

var base = require('base/product/base');
var klarnaInstantShopping = require('theme/js/product/klarna/instantShopping');
var focusHelper = require('theme/js/components/focus');

import FeatureFlagProvider from 'shared/js/featureFlagRegistrar';
import { addQueryToUrl } from 'shared/js/url';
import { aggregateFlagsOnNode } from 'shared/js/dom';
import { VOYADO_EVENT_TRACK_PRODUCT_IMPRESSION } from 'shared/js/voyadoAnalyticsLoader';

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-tile').length) {
        pid = $($el).closest('.product-tile-add-to-cart').find('.product-tile-buy').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    }

    if ($('.add-to-cart').parent().hasClass('donations-add-to-cart') && !pid && $el && $el.length) {
        pid = $el.data('pid');
    }


    if (!pid) {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}


/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
    // There are 2x QTY fields at least on PDP (product details & mini-cart).
    // Firstly find the nearest QTY field, since could be incorrectly resolved QTY field
    // from mini-cart.
        quantitySelected = $($el).siblings('.quantity-select');
        if (!quantitySelected.length) {
            quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
        }
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    if ($el && $el.data('quantity')) {
        return parseInt($el.data('quantity'), 10);
    } else if (getQuantitySelector($el).val()) {
        return getQuantitySelector($el).val();
    }

    return 1;
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
    // master version: var $attrValue = $productContainer.find('.variation-dropdown [data-attr-value="' + attrValue.value + '"]');
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');

        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            // master version: $productContainer.find('.variation-dropdown .dropdown-header .title .attr').text(attrValue.displayValue
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    // Modified version of #SFRA v6 code #CDIFF incompatible #DONE
    // Is left for historical reference purpose & in case of usage for further SFRA upgrade(s).
    // It is not compatible with custom "processNonSwatchValues" implementation, which is preserved.
    // -- start --
    // var $attr = `.variation-span.${attr.id}-value`;
    // var $defaultOption = $productContainer.find(`.variation-dropdown.variation-${attr.displayName} ${$attr}`).first();
    // $defaultOption.attr('value', attr.resetUrl);
    //
    // attr.values.forEach(function (attrValue) {
    //     var $attrValue = $productContainer
    //         .find($attr + '[data-attr-value="' + attrValue.value + '"]');
    //     $attrValue.attr('value', attrValue.url)
    //         .removeAttr('disabled');
    //
    //     if (!attrValue.selectable) {
    //         $attrValue.attr('disabled', true);
    //     }
    // });
    // -- end --

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('.variation-dropdown [data-attr-value="' +
            attrValue.value + '"]');

        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $productContainer.find('.variation-dropdown .dropdown-header .title .attr').text(attrValue.displayValue);
        } else {
            $attrValue.removeClass('selected');
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityStatus = response.product.availability.status;
    var availabilityMessage = response.product.availability.statusLabel;
    var availabilityCheckmark = '';

    if (FeatureFlagProvider.isSite('PF') || FeatureFlagProvider.isSite('BM')) {
        availabilityCheckmark = '<i class="icon-check"></i> ';
    }

    switch (availabilityStatus) {
    case 'LOW STOCK':
        availabilityValue = '<div class="item low-stock">' + availabilityMessage + '</div>';
        break;
    case 'IN STOCK':
        availabilityValue = '<div class="item in-stock">' + availabilityCheckmark + availabilityMessage + '</div>';
        break;
    case 'OUT OF STOCK':
        availabilityValue = '<div class="item out-of-stock">' + availabilityMessage + '</div>';
        break;
    default:
        break;
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function handleOutOfStock(response, $productContainer) {
    var quantityInput = $productContainer.find('.quantity-select');
    var addToCart = $productContainer.find('.add-to-cart');
    var notifyMe = $productContainer.find('.notify-me');
    var noInventory = $productContainer.find('.variation-dropdown .dropdown-header .title .no-inventory');
    var variationDropdownRectangle = $productContainer.find('.variation-dropdown-rectangle');

    if (response.product.availability.status == 'OUT OF STOCK') {
        addToCart.addClass('hide');
        notifyMe.removeClass('hide');
        noInventory.removeClass('hide');
        variationDropdownRectangle.addClass('greyed-out');
        quantityInput.val('-');
        quantityInput.disabled = true;
        quantityInput.siblings('.increase-quantity').addClass('icon--disabled');
        quantityInput.siblings('.decrease-quantity').addClass('icon--disabled');
    } else {
        addToCart.removeClass('hide');
        notifyMe.addClass('hide');
        noInventory.addClass('hide');
        variationDropdownRectangle.removeClass('greyed-out');
        quantityInput.val(1);
        quantityInput.disabled = false;
        quantityInput.siblings('.increase-quantity').removeClass('icon--disabled');
        quantityInput.siblings('.decrease-quantity').addClass('icon--disabled');
    }
}

/**
 * Generates html for promotions section
 *
 * @param {array} promotions - list of promotions
 * @return {string} - Compiled HTML
 */
// SFRA 6 update - adjustments required.
function getPromotionsHtml(product) { // eslint-disable-line no-unused-vars
    var html = '';
    if (product.custom.callOutMsgs && product.custom.callOutMsgs.length > 0) {
        for (var i = 0; i < product.custom.callOutMsgs.length; i++) {
            var calloutMsg = product.custom.callOutMsgs[i];

            switch (calloutMsg.type) {
            case 'topSeller':
                html += '<div class="promotion custom topseller">';
                html += '<span>' + calloutMsg.callOut + '</span></div>';
                break;
            case 'newBadge':
                html += '<div class="promotion new">';
                html += '<span>' + calloutMsg.callOut + '</span></div>';
                break;
            case 'customMsg':
                html += '<div class="promotion custom">';
                html += '<span>' + calloutMsg.callOut + '</span></div>';
                break;
            case 'voyadopromotion':
                html += '<div class="promotion voyado">';
                html += '<span>' + calloutMsg.callOut + '</span></div>';
                break;
            case 'extra':
                html += `<div class="promotion ${calloutMsg.variation ? ('badge--' + calloutMsg.variation) : ''}">`;
                html += '<span>' + calloutMsg.callOut + '</span></div>';
                break;
            default:
                html += '<div class="promotion sale"><span>' + calloutMsg.callOut +
                    '</span></div>';
                break;
            }
        }
    }

    return html;
}

/**
 * Generates html for wishlist button
 *
 * @param {array} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @return {string} - Compiled HTML
 */
function getWishListButtonHtml(response) {
    let html;
    if (response.isLoggedIn) {
        html = `<div x-wishlist:togglestate x-data="{ productId: '${response.product.id}' }"></div>`;
    }

    return html;
}

/**
 * Generates html for promotions section
 *
 * @param {array} promotions - list of promotions
 * @return {string} - Compiled HTML
 */
function handlePromotionBoxOrDisclaimer(promotions, availability) {
    if (availability == 'OUT OF STOCK') {
        $('.product-outofstock-disclaimer').removeClass('hide');
        // #CDIFF #DONE
        // Across project are used two options - either .. or:
        // * version - next: $('.promotion-box').addClass('hidden');
        // SN version - next: $('.product-promotion-boxes').addClass('hide');
        if (FeatureFlagProvider.isSite('SN')) {
            $('.promotion-box').addClass('hidden');
        } else {
            $('.product-promotion-boxes').addClass('hide');
        }
    } else {
        $('.product-outofstock-disclaimer').addClass('hide');
        // Across project are used two options - either .. or:
        // SN, GG, FM version
        if (FeatureFlagProvider.isSite('SN')) {
            $('.product-promotion-boxes').removeClass('hide');
        } else {
            // Others - the next:
            $('.promotion-box').removeClass('hidden');
        }
        if (!promotions) {
            return '';
        }

        var html = '';

        promotions.forEach(function (promotion) {
            html += promotion.details;
        });

        var productDisclaimers = $('.product-disclaimers');
        var productDisclaimersDetails = $('.promotion-details');

        productDisclaimers.children('.product-promotion-boxes').remove();
        productDisclaimersDetails.find('.promotion-box').remove();
        productDisclaimersDetails.html(html);
    }
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    var carousel = $productContainer.find('.carousel');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel).empty().append('<ol class="carousel-indicators"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' + carouselId + '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next" href="#' + carouselId + '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('next') + '</span></a>');
    for (var i = 0; i < imgs.length; i++) {
        $('<div class="carousel-item"><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.length === 1) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    var isSetItem = $productContainer.hasClass('set-item');
    var quickview = $('.quickview-modal').hasClass('show');
    if (quickview) {
        $productContainer = $('.quickview-modal .product-detail');
    }
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update product flavor
    if (quickview) {
        $('.quickview-modal .product-flavor').html(' ' + response.product.custom.flavorName);
    } else {
        $('.product-flavor').html(' ' + response.product.custom.flavorName);
    }

    // Update primary images
    var primaryImageUrls = response.product.images.large;
    createCarousel(primaryImageUrls, $productContainer);

    window.dataLayer.push(response.dataLayerView[0]);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    if (isSetItem) {
    // Update Product Set Item Image
        var setItemImage = response.product.images.setItemImageHtml;
        $productContainer.find('.set-item__image')
            .empty()
            .html(setItemImage);

        // Update Product Set Item ID
        $productContainer.attr('data-pid', response.product.id);
    } else {
    // Update Images Carousel

        var imageCarouselMobile = response.product.images.carouselHtml;
        var imageCarouselDesktop = response.product.images.carouselHtmlDesktop;

        if (quickview) {
            $('.quickview-modal .product-images.desktop').empty().html(imageCarouselDesktop);

            $('.quickview-modal .slick-product-carousel.desktop').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                // Across project are used two options - either .. or:
                // SN, GG version
                // #CDIFF #TBD
                // prevArrow: "<i class='icon-arrow-left-right'></i>", // #CDIFF #DONE
                prevArrow:
                    FeatureFlagProvider.isSite('BS', 'PF', 'FM')
                        ? "<i class='icon-point'></i>"
                        : "<i class='icon-arrow-left-right'></i>", // #CDIFF #DONE
                // SN, GG version
                // nextArrow: "<i class='icon-arrow-left-right right'></i>", // #CDIFF #DONE
                nextArrow:
                    FeatureFlagProvider.isSite('BS', 'PF', 'FM')
                        ? "<i class='icon-point-right'></i>"
                        : "<i class='icon-arrow-left-right right'></i>", // #CDIFF #DONE
            });
        } else {
            $('.product-images.mobile').empty().html(imageCarouselMobile);
            $('.product-images.desktop').empty().html(imageCarouselDesktop);

            $('.slick-product-carousel.mobile').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                // Across project are used two options - either .. or:
                // SN, GG version
                // #CDIFF #TBD
                // prevArrow: "<i class='icon-arrow-left-right'></i>", // #CDIFF #DONE
                prevArrow:
                    FeatureFlagProvider.isSite('BS', 'PF', 'FM')
                        ? "<i class='icon-point'></i>"
                        : "<i class='icon-arrow-left-right'></i>", // #CDIFF #DONE
                // SN, GG version
                // nextArrow: "<i class='icon-arrow-left-right right'></i>", // #CDIFF #DONE
                nextArrow:
                    FeatureFlagProvider.isSite('BS', 'PF', 'FM')
                        ? "<i class='icon-point-right'></i>"
                        : "<i class='icon-arrow-left-right right'></i>", // #CDIFF #DONE
                dots: true
            });

            $('.slick-product-carousel.desktop').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                // Across project are used two options - either .. or:
                // SN, GG version
                // #CDIFF #TBD
                // prevArrow: "<i class='icon-arrow-left-right'></i>", // #CDIFF #DONE
                prevArrow:
                    FeatureFlagProvider.isSite('BS', 'PF', 'FM')
                        ? "<i class='icon-point'></i>"
                        : "<i class='icon-arrow-left-right'></i>", // #CDIFF #DONE
                // SN, GG version
                // nextArrow: "<i class='icon-arrow-left-right right'></i>", // #CDIFF #DONE
                nextArrow:
                    FeatureFlagProvider.isSite('BS', 'PF', 'FM')
                        ? "<i class='icon-point-right'></i>"
                        : "<i class='icon-arrow-left-right right'></i>", // #CDIFF #DONE
            });
        }

        $('.product-swatch-images .swatch').click(function () {
            if (!$(this).hasClass('active')) {
                $(this).siblings('.active').removeClass('active');
                $(this).addClass('active');

                $('.slick-product-carousel.desktop').slick('slickGoTo', ($(this).index()), true);
            }
        });

        $('.slick-product-carousel.desktop').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            var productSwatchImages = $('.product-swatch-images .swatch');
            productSwatchImages.siblings('.active').removeClass('active');
            productSwatchImages.eq(nextSlide).addClass('active');
        });
    }

    // Update promotions
    // #CDIFF #DONE SN uses selector:
    if (FeatureFlagProvider.isSite('SN')) {
        var promotionWrapper = $('.product-top-content').find('.promotion-wrapper');
        promotionWrapper.empty().html(getPromotionsHtml(response.product));
    } else {
    // #CDIFF #MONITOR - vanilla variant: .html(response.product.promotionsHtml)
        // eslint-disable-next-line no-lonely-if
        if (quickview) {
            $('.quickview-modal .product-images .promotion-wrapper .promotions-right').empty().html(getPromotionsHtml(response.product));
        } else {
            $('.product-images .promotion-wrapper .promotions-right').empty().html(getPromotionsHtml(response.product));
        }
    }
    handlePromotionBoxOrDisclaimer(response.product.promotions, response.product.availability.status);

    updateAvailability(response, $productContainer);

    handleOutOfStock(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
    // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));

    // Update nutrition and ingredients
    if (quickview) {
        $('.quickview-modal .product-short-description').empty().html(response.product.shortDescription);
    } else {
        $('.nutrition-and-ingredients').empty().html(response.product.custom.nutritionAndIngredients);
        $('.long-description').empty().html(response.product.longDescription);
        $('.product-short-description').empty().html(response.product.shortDescription);
    }
    // #CDIFF #DONE The next fragment is not present in SN:
    // -- start
    // Preserved code fragment from the legacy storefront code.
    if (response.product.isNewBadge) {
        var newBadgeWrapper;
        if (FeatureFlagProvider.isSite('PF')) {
            // #CDIFF #DONE PF has next alt version:
            newBadgeWrapper = '<div class="promotions-right new-product"><div class="promotion custom new">' + response.product.isNewBadgeLabel + '</div></div>';
            $('.product-images .promotion-wrapper').append(newBadgeWrapper);
        } else {
            newBadgeWrapper = '<div class="promotion new">' + response.product.isNewBadgeLabel + '</div>';
            $('.product-images .promotions-right').append(newBadgeWrapper);
        }
    }
    // #CDIFF GMF #DONE
    if (FeatureFlagProvider.get('integration.gmf.active') && !quickview) {
        $('.item.reviews span').empty().html('<div class="gmf-product-rating" data-compact data-product-id="' + response.product.id + '"></div>');
        $('.description-and-detail').empty().html('<div id="gmf-comment-section" data-product-id="' + response.product.id + '"></div>');
    }

    if (quickview) {
        $('.quickview-modal .ean-code').html(response.product.EAN);
        $('.quickview-modal .promotion-box-tile').removeClass('active');
        $('.quickview-modal .promotion-box-tiles > .promotion-box-tile:nth-child(1)').addClass('active');
    } else {
        $('.ean-code').html(response.product.EAN);
        $('.promotion-box-tile').removeClass('active');
        $('.promotion-box-tiles > .promotion-box-tile:nth-child(1)').addClass('active');
    }
    // -- end

    var parentElement;
    if (quickview) {
        parentElement = $('.quickview-modal-content');
    } else {
        parentElement = $('.quantity-and-add-to-cart');
    }

    var trackingPayload = parentElement ? parentElement.find('button.add-to-cart')[0] : undefined;
    var trackingEventPayload = trackingPayload ? JSON.parse(trackingPayload.dataset.trackingEventPayload) : undefined;
    if (trackingEventPayload) {
        trackingEventPayload.add.products[0].id = response.product.id;
        trackingEventPayload.add.products[0].name = response.product.productName;
        trackingEventPayload.add.products[0].variant = response.product.custom.flavorName;
        trackingPayload.dataset.trackingEventPayload = JSON.stringify(trackingEventPayload);
    }
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {string} quantityHtml - The innerHTML to be used to update the quantity elements
 * @param {jQuery} $productContainer - The container for the product
 */
function updateQuantities(quantityHtml, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        $('.quantity-and-add-to-cart .attribute').empty().html(quantityHtml);
    }
}

/**
 * Updates the visibility of the quantity callout based on the minOrderQuantity.
 * @param {number} minOrderQuantity - The minimum order quantity for the product.
 */
function updateCallouts(minOrderQuantity) {
    var quantityCallout = $('[data-quantity-callout]').first();
    if (quantityCallout && minOrderQuantity && minOrderQuantity <= 1) {
        quantityCallout.addClass('hide');
    } else {
        quantityCallout.removeClass('hide');
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.quantityHtml, $productContainer);
                updateCallouts(parseInt(data.product.minOrderQuantity, 10));
                $('.product-outofstock-popup-pid-form-input').val(data.product.id);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                // #CDIFF #DONE Klarna integration point
                // The next fragment is not present in SN
                // -- start
                if (FeatureFlagProvider.get('checkout.klarna.active')
                    && FeatureFlagProvider.get('checkout.klarna.instantshopping.active')) {
                    klarnaInstantShopping.init();
                    klarnaInstantShopping.update(data);
                }
                // -- end

                var isQuickView = $('#quickViewModal').is(':visible');
                var categoryNameSelector = isQuickView ? '#quickViewModal .breadcrumb' : '.breadcrumb';
                document.dispatchEvent(
                    new CustomEvent(VOYADO_EVENT_TRACK_PRODUCT_IMPRESSION, {
                        detail: {
                            categoryName: $(categoryNameSelector).text().replace(/\n/g, '').replace(/\s+/g, ' ').trim(),
                            itemId: data.product.id,
                            locale: window.currentLocale,
                            contactId: $('[data-voyado-contact-id]').data('voyado-contact-id')
                        }
                    })
                );
                $.spinner().stop();
                window.setWishlistState && window.setWishlistState(data.product.id);
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade modal-xl choose-bonus-product-modal" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog choose-bonus-product-modal-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header choose-bonus-product-modal-header">'
        + '    <span class="choose-bonus-product-modal-header-title">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div data-chosen-bonus-product-variant="" data-chosen-bonus-product-name="" class="modal-body choose-bonus-product-modal-body"></div>'
        + '<div class="modal-footer choose-bonus-product-modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    }
    /**
    Code is not used at the moment. It might be needed in the future

    else {
        var messageType = response.error ? 'alert-danger' : 'alert-success';

        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
    */
}

base.methods.getOptions = getOptions;

function selectBonusProduct() {
    $(document).on('click', '.select-bonus-product', function () {
        var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
        var pid = $(this).attr('data-pid');
        var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
        var submittedQty = parseInt($(this).parents('.choice-of-bonus-product').find('.bonus-quantity-select').val(), 10);
        var totalQty = 0;
        $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
            totalQty += $(this).data('qty');
        });
        totalQty += submittedQty;
        var optionID = $(this).parents('.choice-of-bonus-product').find('.product-option').data('option-id');
        var valueId = $(this).parents('.choice-of-bonus-product').find('.options-select option:selected').data('valueId');

        if (totalQty <= maxPids) {
            /** Set the bonus product chosen variant */
            var variantName = $choiceOfBonusProduct.find('option:selected').attr('data-attr-value');
            $('.choose-bonus-product-modal-body').attr('data-chosen-bonus-product-name', variantName);

            var selectedBonusProductHtml = ''
                + '<div class="selected-pid row" '
                + 'data-pid="' + pid + '"'
                + 'data-qty="' + submittedQty + '"'
                + 'data-optionID="' + (optionID || '') + '"'
                + 'data-option-selected-value="' + (valueId || '') + '"'
                + '>'
                + '<div class="col-sm-11 col-9 bonus-product-name" >'
                + $choiceOfBonusProduct.find('.product-name').html() + ' ' + $('.choose-bonus-product-modal-body').attr('data-chosen-bonus-product-name')
                + '</div>'
                + '<div class="col-1"><button class="remove-selected-bonus-product" aria-hidden="true">x</button></div>'
                + '</div>'
            ;
            $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
            $('.pre-cart-products').html(totalQty);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        } else {
            $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
        }
    });
}

function selectBonusProductAttribute() { // eslint-disable-line no-unused-vars
    $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
        $(document).on('change', '.bonusSelect', function (e) {
            e.preventDefault();
            $('.select-bonus-product').attr('disabled', 'disabled');
            $(this).attr('selected');
            var $productContainer = $(this).parents('.bonus-product-item');
            var url = $(this).val();

            $.ajax({
                url: url,
                method: 'GET',
                success: function (data) {
                    if (data.product) {
                        /** Update Variant Image In Modal */
                        var variantBonusProductImg = data.product.images.hiRes[0].url;
                        $productContainer.find('.carousel-item img').attr('src', variantBonusProductImg);

                        /** Update Variant Id in the selected PID Row to add the correfct variant to as a bonus to the cart */
                        var pid = data.product.id;
                        $('.choose-bonus-product-modal-body').attr('data-chosen-bonus-product-variant', pid);
                        $productContainer.find('.select-bonus-product').attr('data-pid', pid);
                    }

                    $.spinner().stop();
                    $('.select-bonus-product').removeAttr('disabled');
                },
                error: function () {
                    $.spinner().stop();
                    $('.select-bonus-product').removeAttr('disabled');
                }
            });
        });
    });
}


/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

module.exports = {
    ...base,
    attributeSelect: attributeSelect,


    focusChooseBonusProductModal: function () {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
    // #CDIFF GG <> BS - however in newer code version - only one variant is used #DONE
    // Different across project, alt option (like in GG)
    // Alt: $(document).on('click', '[data-attr="color"] button', function (e) {
        $(document).on('click', '.variation-dropdown .variation-item', function (e) {
            e.preventDefault();

            // Symplify script checking every variation change
            if (typeof symplify !== 'undefined') {
                symplify.newPage();
            } else {
                document.addEventListener('symplify-loaded', function () {
                    symplify.newPage();
                });
            }

            $(this).closest('.dropdown').removeClass('active');

            if ($(this).attr('disabled') || $(this).children('.selected').length > 0) {
                return;
            }

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            attributeSelect($(this).attr('data-url'), $productContainer);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer);
            }
        });
    },

    addToCart: function () {
    // #CDIFF #DONE Different across sites, alt option:
    // $(document).on('click', 'button.add-to-cart, button.add-to-cart-global, .product-link-to-static', function () {
        let selectorButtonAddToCart;
        if (FeatureFlagProvider.isSite('SN')) {
            selectorButtonAddToCart = 'button.add-to-cart, button.add-to-cart-global, .product-link-to-static';
        } else {
            selectorButtonAddToCart = 'button.add-to-cart, button.add-to-cart-global';
        }
        $(document).on('click', selectorButtonAddToCart, function (evt) {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;

            // #TODO FF
            if ($(this).hasClass('donations-button')) {
                $(this).addClass('hide');
                $(this).next().removeClass('hide');

                $.spinner().start();
            }

            // #CDIFF #TODO SN does not have donation button

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });

                pidsObj = JSON.stringify(setPids); // #CDIFF BS [<> GG - eq. in newer code version] <> SN #MONITOR
            } else { // Handles custom product sets
                var setProducts = $('.set-product-dropdown .dropdown-header');

                if (setProducts && setProducts.length > 0) {
                    var setProductObjects = [];
                    setProducts.each(function () {
                        setProductObjects.push({
                            pid: this.dataset.pid,
                            quantity: this.dataset.quantity
                        });
                    });

                    pidsObj = JSON.stringify(setProductObjects);
                }
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this))
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addQueryToUrl(
                        addToCartUrl,
                        aggregateFlagsOnNode(evt.target || evt.currentTarget)
                    ),
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        if (data.error) {
                            window.MessagesMgr.error(data.errorMessage);
                        }

                        if (data.isOnCheckoutPage) {
                            $('body').trigger('cart:update', ['add_to_cart', data.cart]);
                        }
                        handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);

                        if (!FeatureFlagProvider.isSite('SN')) {
                            miniCartReportingUrl(data.reportingURL);
                        }

                        // -- start - SN does not have #CDIFF #DONE
                        if (!FeatureFlagProvider.isSite('SN')) {
                            if (data.quantityTotal > 0) {
                                $('.minicart-quantity').removeClass('empty-cart');
                                $('.minicart .icon-cart').removeClass('empty-cart-trigger');
                                $('.price-header').removeClass('empty-cart');
                            }
                        }

                        if (!FeatureFlagProvider.isSite('SN') && !data.error) {
                            // #CDIFF Voyado integration #DONE SN does not have it
                            document.dispatchEvent(
                                new CustomEvent('Voyado-monitor-cart', {
                                    detail: {
                                        uuid: data.cart.basketUUID,
                                        items: data.cart.items,
                                        locale: data.locale
                                    }
                                })
                            );
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                }).always(function () {
                    $.spinner().stop();
                });
            }
        });
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    chooseBonusProducts: chooseBonusProducts,
    selectBonusProduct: selectBonusProduct,
    selectBonusProductAttribute: selectBonusProductAttribute
};

module.exports.methods.editBonusProducts = function (data) {
    chooseBonusProducts(data);
};
